<template>
    <div class="containergeral">

    
        <div class="portal" v-if="modulo=='inicio'">
                <div class="topo">
                    <div style="max-width:50vw;min-width:50vw;height:100%;background-color:gray;">
                        <!-- <v-img class="imgtopo" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeiAMA5mlKI9xqzYg3ymCyGQXor52YIengLfWRKlK65wa7Ca290jZ_2fTb6qQe7jzGT-I&usqp=CAU"> -->
                        <v-img class="imgtopo" src="/imgPortal/logo_esmeralda.webp">
                        </v-img>
                    </div>
                </div>
                <div class="titulo">
                    <div style="max-width:100vw;padding-left:20%;padding-right:20%;text-align:center;">
                        <h3>Evite Filas e seja um Cliente Digital</h3>
                    </div>
                </div>
                <div class="body">

                    <div class="cardportal">
                        <a @click="setarUrl('checkinpay')" >
                            <v-img class="imgCardPortal" src="/imgPortal/checkin-pay.png">
                            </v-img>
                            <h3 class="titulocardPortal">Faça aqui o seu Check In Pay</h3>
                        </a>
                    </div>

                    <div class="cardportal">
                        <a @click="setarUrl('webcheckin')">
                            <v-img class="imgCardPortal" src="/imgPortal/web-checkin.png">
                            </v-img>
                            <h3 class="titulocardPortal">Faça aqui seu Check In Digital</h3>
                        </a>
                    </div>

                    <div class="cardportal">
                        <a @click="setarUrl('cardapio')" >
                            <v-img class="imgCardPortal" src="/imgPortal/cardapio.png">
                            </v-img>
                            <h3 class="titulocardPortal">Faça aqui o seu pedido no Cardápio Digital</h3>
                        </a>
                    </div>

                    <div class="cardportal">
                        <a @click="setarUrl('checkout')" >
                            <v-img class="imgCardPortal" src="/imgPortal/checkout.png">
                            </v-img>
                            <h3 class="titulocardPortal">Faça aqui seu Checkout Digital</h3>
                        </a>
                    </div>

                    <div class="cardportal">
                        <a @click="setarUrl('fdguest')" >
                            <v-img class="imgCardPortal" src="/imgPortal/fd-guest.png">
                            </v-img>
                            <h3 class="titulocardPortal">Faça aqui suas solicitações ao Concierge</h3>
                        </a>
                    </div>

                </div>
        </div>

        <div class="portal" v-else>
            <iframe class="portalframe" :src="url" frameborder="0"></iframe>
        </div>

<input type="file" accept="image/*" capture aria-orientation="" />

        <div class="rodape">
                <v-btn large block elevation="" @click="voltarInicio()" style="width:100%;height:100%;" color="info">Voltar ao Inicio</v-btn>
        </div>

    </div>
</template>

<script>
// @ is an alias to /src
// import HomePage from '@/components/Home.vue'
// import HomePage from '@/components/HomeImplantacao.vue'

export default {
    name: 'Home',
    components: {
        // HomePage
    },
    data(){
        return {
            url:'',
            modulo:'inicio',
        }
    },
    methods:{
        voltarInicio(){
            this.modulo= 'inicio'
        },
        setarUrl(tipo){
            if(tipo == 'checkinpay'){
                this.modulo= tipo
                this.url = 'https://esmeralda.fd.economysoftware.com.br/checkinpayacesso/1?idc=21&hasg=12fhfuy'
            }

            if(tipo == 'webcheckin'){
                this.modulo= tipo
                this.url = 'https://esmeralda.fd.economysoftware.com.br/webcheckinacesso/1?idc=21&hasg=12fhfuy'
            }

            if(tipo == 'cardapio'){
                this.modulo= tipo
                this.url = 'https://esmeralda.cd.economysoftware.com.br/?idc=21&hasg=12fhfuy'
            }

            if(tipo == 'checkout'){
                this.modulo= tipo
                this.url = 'https://esmeralda.fd.economysoftware.com.br/webcheckout/1?idc=21&hasg=12fhfuy'
            }

            if(tipo == 'fdguest'){
                this.modulo= tipo
                this.url = 'https://esmeralda.fdguest.economysoftware.com.br?idc=21&hasg=12fhfuy'
            }
        },
    }
  
}
</script>

<style lang="scss">
    .portalframe{
       width: 100%;
       height: 100%; 
    }
    .containergeral{
        height:100vh;
    }
    .portal{
        // background-color:red;
        width: 100%;
        height: 94%;
        min-height: 94%;
        max-height: 94%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        // padding-top:20px;
    }
    .rodape{
        width:100%;
        height:6%;
        max-height:6%;
        min-height:6%;
        // background-color: red;
        // padding:1px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .topo{
        // background-color:blue;
        margin-top:10px;
        width: 100%;
        max-height: 10vh;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .body{
        // background-color:blue;
        margin-top:20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px;
    }
    .imgtopo{
        height: 8vh;
        // width: 350px;
    }
    .cardportal{
        margin-bottom:5vh;
        width:40vw;
        height:50vw;
        // background-color: orange;
    }
    .titulocardPortal{
        margin-top:2vw;
        width:40vw;
        height:5vw;
        // background-color: pink;
    }
    .imgCardPortal{
        width:40vw;
        height:33vw;
        border-radius: 10px;
    }
    .titulo{
        margin-top:10px;
        padding:10px;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
    }
    
</style>
